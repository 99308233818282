import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './pages/Home'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import Footer from './components/Footer'
import AboutPage from './pages/About'
import ServicesPage from './pages/Services'
import ContactPage from './pages/Contact'
import Partners from './pages/Partners'
document.documentElement.lang = 'sv'

export interface IApplicationProps {}

// Blue:    #1A2954
// Orange:  #E48F22
// Main:    #f5f0f1
// Brown:   #715236

const Application: React.FC<IApplicationProps> = props => {
  const amabTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#1A2954',
      },
      text: {
        primary: '#f5f0f1',
      },
    },
  })

  return (
    <ThemeProvider theme={amabTheme}>
      <ResponsiveAppBar />
      <BrowserRouter>
        <Routes>
          const navItems = ["Home", "Services", "About", "Contact", "Partners"];
          <Route path="/" element={<HomePage />}></Route>
          <Route path="home" element={<HomePage />}></Route>
          <Route path="hem" element={<HomePage />}></Route>
          <Route path="services" element={<ServicesPage />}></Route>
          <Route path="tjänster" element={<ServicesPage />}></Route>
          <Route path="about" element={<AboutPage />}></Route>
          <Route path="om" element={<AboutPage />}></Route>
          <Route path="contact" element={<ContactPage />}></Route>
          <Route path="kontakt" element={<ContactPage />}></Route>
          <Route path="partners" element={<Partners />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Application
