import React from 'react'
import { Box, Container, Grid, Tooltip, Typography } from '@mui/material'
import Classe from '../pictures/classe-1-1.jpg'
import Janne from '../pictures/janne-1-1.jpg'
document.documentElement.lang = 'sv'

export interface IAboutPageProps {}

const AboutPage: React.FC<IAboutPageProps> = props => {
  return (
    <Box>
      <Container maxWidth="md">
        <Box pt={2} sx={{ minHeight: '75vh' }}>
          <Typography variant="h3" align={'center'} color={'#E48F22'}>
            Om oss
          </Typography>
          <Typography mt={2} variant={'body1'} align={'left'}>
            Åter Metall i Strängnäs AB eller ÅMAB som företaget kommer att heta i tal och skrift. ÅMAB har sitt säte i
            Strängnäs och är startat under våren/sommaren 2023 så det är ett alldeles nytt företag.
          </Typography>
          <Typography mt={2} variant={'body1'} align={'left'}>
            Företaget bildades av bröderna Janne och Classe Boström som har lång erfarenhet inom en rad områden och för
            ÅMAB en alldeles speciell affärsidé som vi tror alldeles extra mycket på.
          </Typography>
          <Grid mt={6} container>
            <Grid item md={9} xs={12}>
              <Typography component="div" mt={2} variant={'body1'} align={'left'}>
                <Box translate={'no'} fontWeight="bold" display="inline" color={'#E48F22'}>
                  Classe Boström{' '}
                </Box>
                – Ekonomi och administration i bolaget. Tidigare erfarenhet som VD, kommundirektör och olika
                bolagsstyrelser. Ledarutveckling inom elitidrott, privata företag och offentlig verksamhet.
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid mt={2} item md={2} xs={12}>
              <Tooltip title="Classe" placement="right">
                <img width={'100%'} src={Classe} alt={'Classe Boström'} />
              </Tooltip>
            </Grid>
            <Grid item md={9} xs={12}>
              <Typography component="div" mt={2} variant={'body1'} align={'left'}>
                <Box translate={'no'} fontWeight="bold" display="inline" color={'#E48F22'}>
                  Janne Boström{' '}
                </Box>
                – VD och försäljningsansvarig i bolaget. Tidigare erfarenhet från nyetablering inom metallåtervinning.
                Janne har en mångårig erfarenhet som försäljningschef och projektledning inom rivning, materialsortering
                och återvinning.
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid mt={2} item md={2} xs={12}>
              <Tooltip title="Janne" placement="right">
                <img src={Janne} width={'100%'} alt={'Janne Boström'} />
              </Tooltip>
            </Grid>

            <Grid mb={2} item xs={12}>
              <Typography mt={6} variant="h5" align={'left'} color={'#E48F22'}>
                Styrelesen
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography component="div" mt={1}>
                <Box translate={'no'} fontWeight="bold" display="inline">
                  Classe Boström{' '}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography component="div" mt={1}>
                <Box translate={'no'} fontWeight="bold" display="inline">
                  Janne Boström{' '}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography component="div" mt={1}>
                <Box translate={'no'} fontWeight="bold" display="inline">
                  Ulf Morelius{' '}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography component="div" mt={1}>
                <Box translate={'no'} fontWeight="bold" display="inline">
                  Patrik Bysell{' '}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default AboutPage
