import React from 'react'
import { Box, Container, Grid, Link, Typography } from '@mui/material'
import Project from '../pictures/project-1-1.jpg'
import Truck from '../pictures/truck-1-1.jpg'
document.documentElement.lang = 'sv'

export interface IServicesPagePageProps {}

const ServicesPage: React.FC<IServicesPagePageProps> = props => {
  return (
    <Box>
      <Container maxWidth="md">
        <Box pt={2} sx={{ minHeight: '75vh' }}>
          <Typography variant="h3" align={'center'} color={'#E48F22'}>
            Tjänster
          </Typography>

          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography mt={4} variant="h5" color={'#E48F22'}>
                Återvinning/återbruk
              </Typography>
              <Typography mt={2} variant={'body1'} align={'left'}>
                Vi sorterar direkt vid rivning, rätt material i rätt container ger bästa pris till dig som kund.
              </Typography>
              <Typography mt={2} variant={'body1'} align={'left'}>
                Mycket material som kan återvinnas/återbrukas slängs tyvärr och det vill vi hjälpa till att ändra på.
              </Typography>
              <Typography mt={2} variant={'body1'} align={'left'}>
                När material som borde återvinnas/återbrukas istället slängs, blandas många metaller. Detta medför att
                du som lämnare inte erhåller den ersättning du är berättigad till vid korrekt hantering.
              </Typography>
              <Typography mt={2} variant={'body1'} align={'left'}>
                Vi vill göra ändring på detta och se till att ni får rätt ersättning på det som återvinns. Men också att
                det som kan återbrukas faktiskt återbrukas och kommer till nytta.
              </Typography>
              <Typography mt={2} variant={'body1'} align={'left'}>
                <Link color={'#E48F22'} href={'Contact'}>
                  Kontakta oss
                </Link>{' '}
                så att vi tillsammans kan gå igenom ert företags behov. Vi följer upp detta med en projektplan samt
                offert.
              </Typography>
            </Grid>
            <Grid mt={6} item xs={12} md={6}>
              <img src={Truck} width={'100%'} alt={'Truck'} />
            </Grid>

            <Grid item xs={12}>
              <Typography mt={2} variant={'body1'} align={'left'}>
                Med den erfarenhet som vi byggt upp med de uppdrag och anställningar vi tidigare haft. Kan vi förutom
                rätt återvinning/återbruk till rätt pris och korrekt hantering, även erbjuda dig:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mt={6} variant="h5" color={'#E48F22'}>
                Projektledning
              </Typography>
              <Typography mt={2} variant={'body1'} align={'left'}>
                Rivning och sortering av industri - lantbruk -mm
              </Typography>
              <Typography mt={6} variant="h5" color={'#E48F22'}>
                Konsultverksamhet
              </Typography>
              <Typography mt={2} variant={'body1'} align={'left'}>
                Interimschef - ledarskap - organisation - och försäljning.
              </Typography>
              <Typography component="div" mt={8} variant={'body1'} noWrap>
                <Box display="inline" color={'#E48F22'}>
                  ÅMAB{' '}
                </Box>
                - Ett företag som är en del av det nya.
              </Typography>
            </Grid>
            <Grid mt={6} item xs={12} md={6}>
              <img src={Project} width={'100%'} alt={'Project1'} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default ServicesPage
