import React from 'react'
import { Box, Container, Grid, Link, Tooltip, Typography } from '@mui/material'
import sfc from '../pictures/partner-sfc.png'
import recover from '../pictures/partner-recover-1.png'
document.documentElement.lang = 'sv'

export interface IPartnersPage { }

const PartnersPage: React.FC<IPartnersPage> = props => {
  return (
    <Box>
      <Container maxWidth="md">
        <Box pt={2} sx={{ minHeight: '75vh' }}>
          <Typography variant="h4" align={'center'} color={'#E48F22'}>
            Våra samarbetspartners
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography mt={2} variant={'body1'} align={'left'}>
                Vi är stolta över att presentera våra samarbetspartners. Klicka på företagets logotyp för att få mer information på respektive företags hemsida.
              </Typography>
            </Grid>

            <Grid mt={10} item xs={12} md={5}>
              <a href="https://recover.se/">
                <img src={recover} width={'100%'} alt={'Recover riv % håltagning AB'} />
              </a>
            </Grid>
            <Grid md={2}></Grid>
            <Grid mt={10} item xs={12} md={5}>
              <a href="https://sfconstruction.se/">
                <img src={sfc} width={'100%'} alt={'SF Construction'} />
              </a>
            </Grid>

          </Grid>
        </Box>

      </Container>

    </Box>
  )
}

export default PartnersPage
