import React from 'react'
import { Box, Card, CardContent, Container, Grid, Link, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
document.documentElement.lang = 'sv'

export interface IContactPagePageProps {}

const ContactPage: React.FC<IContactPagePageProps> = props => {
  return (
    <Box>
      <Container maxWidth="md">
        <Box pt={2} sx={{ minHeight: '75vh' }}>
          <Typography variant="h3" align={'center'} color={'#E48F22'}>
            Kontakt
          </Typography>
          <Grid container mt={4} justifyContent="center">
            <Grid item xs={12} md={5} mx={2} mb={2}>
              <Card translate={'no'} sx={{ backgroundColor: '#1A2954', border: 3, borderColor: '#E79538' }}>
                <CardContent>
                  <Typography variant={'h5'}>Janne Boström</Typography>
                  <Typography mb={6}>VD </Typography>
                  <Box display={'flex'}>
                    <PhoneIcon />
                    <Link variant={'h6'} ml={2} pb={5} href={`tel:+46736123821"`}>
                      073-612 38 21
                    </Link>
                  </Box>
                  <Box display={'flex'}>
                    <EmailIcon />
                    <Link ml={2} href={`mailto:janne.bostrom@atermetall.se`} variant={'h6'}>
                      janne.bostrom@atermetall.se
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={5} mx={2}>
              <Card translate={'no'} sx={{ backgroundColor: '#1A2954', border: 3, borderColor: '#E79538' }}>
                <CardContent>
                  <Typography variant={'h5'}>Classe Boström</Typography>
                  <Typography mb={6}>Administration</Typography>
                  <Box display={'flex'}>
                    <PhoneIcon />
                    <Link variant={'h6'} ml={2} pb={5} href={`tel:+46735775703"`}>
                      073-577 57 03
                    </Link>
                  </Box>
                  <Box display={'flex'}>
                    <EmailIcon />
                    <Link ml={2} href={`mailto:classe.bostrom@atermetall.se`} variant={'h6'}>
                      classe.bostrom@atermetall.se
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item mt={8} xs={12} justifyContent="center" display={'flex'}>
              <Typography mr={1}>
                Du är även välkommen att kontakta oss på:{' '}
                <Link color={'#E48F22'} href={`mailto:info@atermetall.se`}>
                  info@atermetall.se
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default ContactPage
