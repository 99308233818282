import React from 'react'
import { Box, Grid, keyframes, Link } from '@mui/material'
import MainLogoCroppedSvg from '../pictures/amab-logo-crop-crip.svg'

import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  Animator,
  batch,
  Fade,
  FadeIn,
  MoveIn,
  MoveOut,
  ScrollContainer,
  ScrollPage,
  Sticky,
} from 'react-scroll-motion'
import FactoryHome from '../pictures/factory-home.jpg'
import FactoryDemont from '../pictures/factory-demont.jpg'
import FactoryValue from '../pictures/factory-value.jpg'
document.documentElement.lang = 'sv'

export interface IHomePageProps {}

const BounceTransform = keyframes`
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-10px);}
  60% {transform: translateY(-5px);}
  `

const BounceDownIcon = styled(ArrowDownwardIcon)({
  animation: `${BounceTransform} 2s ease infinite`,
  alternate: true,
  fontSize: '3rem',
})

const HomePage: React.FC<IHomePageProps> = props => {
  return (
    <>
      <ScrollContainer>
        <ScrollPage>
          <Grid container justifyContent={'center'}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Animator animation={batch(FadeIn(), MoveIn(-1000, 0), MoveOut(0, -400))}>
                <Box display={'flex'} justifyContent={'center'} sx={{ mb: { xs: 1, sm: 7 } }}>
                  <Typography component="div" display={'inline'}>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      Det smarta sättet att{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }} color={'#E48F22'}>
                      återvinna{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      material.
                    </Typography>
                  </Typography>
                </Box>
              </Animator>
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={1} sm={5}></Grid>
            <Grid item xs={10} sm={2}>
              <Animator animation={batch(Fade(), MoveOut(0, -400))}>
                <Box sx={{ mb: { xs: 1, sm: 7, md: 7 } }}>
                  <img width={'100%'} src={MainLogoCroppedSvg} alt="ÅMAB logo" />
                </Box>
              </Animator>
            </Grid>
            <Grid item xs={1} sm={5}></Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Animator animation={batch(FadeIn(), MoveIn(-1000, 0), MoveOut(0, -400))}>
                <Box sx={{ mb: { xs: 1, sm: 7, md: 7 } }} display={'flex'} justifyContent={'center'}>
                  <Typography component="div" display={'inline'}>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      Det smarta sättet att{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }} color={'#E48F22'}>
                      återbruka{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      material.
                    </Typography>
                  </Typography>
                </Box>
              </Animator>
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item sm={12}>
              <Animator animation={batch(Fade(), Sticky(50, 90))}>
                <BounceDownIcon />
              </Animator>
            </Grid>
          </Grid>
        </ScrollPage>

        <ScrollPage>
          <Grid mt={10} container alignItems={'center'}>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={10} md={5}>
              <Animator animation={batch(FadeIn(), MoveIn(-1000, 0), MoveOut(0, -400))}>
                <Box mb={2} display={'flex'} justifyContent={'center'}>
                  <Typography component="div" display={'inline'}>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      Vi tar hand om{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }} color={'#E48F22'}>
                      metall{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      du vill bli av med.
                    </Typography>
                  </Typography>
                </Box>
              </Animator>
            </Grid>
            <Box component={Grid} item xs={1} display={{ md: 'none' }}></Box>

            <Box component={Grid} item xs={1} display={{ md: 'none' }}></Box>
            <Grid item xs={10} md={5}>
              <Animator animation={batch(FadeIn(), MoveIn(1000, 0), MoveOut(0, -400))}>
                <Box display={'flex'} justifyContent={'center'}>
                  <img width={'100%'} src={FactoryHome} alt="Factory1" />
                </Box>
              </Animator>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
        </ScrollPage>

        <ScrollPage>
          <Grid mt={10} container alignItems={'center'}>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={10} md={5}>
              <Animator animation={batch(FadeIn(), MoveIn(-1000, 0), MoveOut(0, -400))}>
                <Box mb={2} display={'flex'} justifyContent={'center'}>
                  <Typography component="div" display={'inline'}>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      Vi hjälper dig att{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }} color={'#E48F22'}>
                      demontera{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      hela fabriker.
                    </Typography>
                  </Typography>
                </Box>
              </Animator>
            </Grid>
            <Box component={Grid} item xs={1} display={{ md: 'none' }}></Box>

            <Box component={Grid} item xs={1} display={{ md: 'none' }}></Box>
            <Grid item xs={10} md={5}>
              <Animator animation={batch(FadeIn(), MoveIn(1000, 0), MoveOut(0, -400))}>
                <Box display={'flex'} justifyContent={'center'}>
                  <img width={'100%'} src={FactoryDemont} alt="Factory2" />
                </Box>
              </Animator>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
        </ScrollPage>

        <ScrollPage>
          <Grid mt={10} container alignItems={'center'}>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={10} md={5}>
              <Animator animation={batch(FadeIn(), MoveIn(-1000, 0), MoveOut(0, -400))}>
                <Box mb={2} display={'flex'} justifyContent={'center'}>
                  <Typography component="div" display={'inline'}>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      Vi kommer ut och gör en{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }} color={'#E48F22'} noWrap>
                      värdering{' '}
                    </Typography>
                    <Typography display={'inline'} sx={{ typography: { xs: 'h4', md: 'h3' } }}>
                      av metall du vill bli av med.
                    </Typography>
                  </Typography>
                </Box>
              </Animator>
            </Grid>
            <Box component={Grid} item xs={1} display={{ md: 'none' }}></Box>

            <Box component={Grid} item xs={1} display={{ md: 'none' }}></Box>
            <Grid item xs={10} md={5}>
              <Animator animation={batch(FadeIn(), MoveIn(1000, 0), MoveOut(0, -400))}>
                <Box display={'flex'} justifyContent={'center'}>
                  <img width={'100%'} src={FactoryValue} alt="Factory3" />
                </Box>
              </Animator>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
        </ScrollPage>

        <ScrollPage>
          <Typography component="div" display={'inline'}>
            <Animator animation={batch(FadeIn(), MoveIn(-1000, 0), Sticky())}>
              <Typography variant="h4">Har du frågor eller funderingar?</Typography>
              <Typography variant="h4">Välkommen att kontakta oss på</Typography>
              <Typography variant="h4">
                <Link color={'#E48F22'} href={`mailto:info@atermetall.se`}>
                  info@atermetall.se
                </Link>
              </Typography>
            </Animator>
          </Typography>
        </ScrollPage>
      </ScrollContainer>
    </>
  )
}

export default HomePage
