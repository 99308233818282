import Box from '@mui/material/Box'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import { Container, Grid } from '@mui/material'
document.documentElement.lang = 'sv'
export interface IFooterProps {}

function Copyright() {
  return (
    <Container maxWidth="xl" sx={{ background: '#1A2954' }}>
      <Grid container>
        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: { lg: 'left', xs: 'center' },
            alignItems: { lg: 'left', xs: 'center' },
            mb: { lg: 0, xs: 2 },
          }}
        ></Grid>
        <Grid item lg={4} xs={12}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography mr={1} variant="body2" color="text.secondary" align="center">
              {'Copyright © Åter Metall Strängnäs AB '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

const Application: React.FC<IFooterProps> = props => {
  return (
    <Box sx={{ bgcolor: '#1A2954', p: 6 }} component="footer">
      <Copyright />
    </Box>
  )
}
export default Application
