import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import logoCropped from '../pictures/amab-logo-crop-crip.svg'
document.documentElement.lang = 'sv'

interface Props {
  window?: () => Window
}

const drawerWidth = 240
const navItems = ['Home', 'Services', 'About', 'Partners', 'Contact']
let translatedNavItems: { [key: string]: string } = {
  Home: 'Hem',
  Services: 'Tjänster',
  About: 'Om oss',
  Partners: 'Sammarbetspartners',
  Contact: 'Kontakt',
}

let selectedTab: string = 'Home'
const url = window.location.href.toLowerCase()

navItems.forEach(item => {
  if (url.includes(item.toLowerCase())) {
    selectedTab = item;
  }
});

function ResponsiveAppBar(props: Props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img height={70} src={logoCropped} alt="Återmetall logo" />
      <Divider />
      <Divider />
      <List>
        {navItems.map(item => (
          <ListItem key={item} disablePadding>
            <Button href={item} key={item} sx={{ color: item === selectedTab ? '#E48F22' : '#fff' }}>
              <ListItemText translate={'no'} primary={translatedNavItems[item]} />
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" style={{ background: '#1A2954' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            <Box display={'flex'}>
              <img height={60} src={logoCropped} alt="ÅMAB logo" />
            </Box>
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map(item => (
              <Button translate={'no'} href={item} key={item} sx={{ color: item === selectedTab ? '#E48F22' : '#fff' }}>
                {translatedNavItems[item]}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          PaperProps={{
            sx: {
              background: '#1A2954',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  )
}

export default ResponsiveAppBar
